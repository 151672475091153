<template>
  <vuestic-widget>
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Departments</span>
        <div class="d-flex justify-content-end align-items-center">
          <a href="javascript:;" class="btn btn-primary btn-sm" @click="onNewDepartment()"><span>New Department <i
                class="fa fa-plus"></i></span></a>
        </div>
      </div>
    </template>
    <div class="row">
      <div class="col-md-12">
        <p :class="{ 'text-primary': newDepartmentAvailable, 'text-danger': !newDepartmentAvailable }">
          <span>Max number of departments: <b>{{ maxDepartments }}</b></span>
        </p>
      </div>
      <div class="col-md-12">
        <div class="d-flex justify-content-center" v-if="fetching">
          <span class="atom-spinner-wrapper">
            <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)" />
          </span>
        </div>
        <datatable v-else v-bind="this.tableConfig" class="le-datatable" />
      </div>
    </div>

    <vuestic-modal :isOpen="isOpenModalDelete" :hideDefaultActions="true" @cancel="closeModalDelete"
      :cancelShown="false" :okShown="false">
      <span slot="title" class="text-danger font-weight-bold">Delete Department</span>
      <p>Are you sure you want to delete this department <strong>"{{
      departmentSelected? departmentSelected.name :
      ''}}"</strong></p>
      <div class="row mt-5">
        <div class="col-md-12 d-flex justify-content-center">
          <button @click="onDeleteDepartment()" class="btn btn-danger" :disabled="processing">
            <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
            <span v-else>Delete</span>
          </button>
        </div>
      </div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenAlertModal" @cancel="closeModalAlert" cancelText="I Got It" :okShown="false"
      cancelClass="btn btn-danger btn-primary">
      <span slot="title" class="text-danger font-weight-bold">Alert</span>
      <p>You don't have any departments available. If you would like to add a new department you must delete an existing
        department. Please reach out at <a class="link" v-bind:href="'mailto:' + agencyOwnerEmail">{{ agencyOwnerEmail }}</a> if you have any
        questions.</p>
    </vuestic-modal>
  </vuestic-widget>
</template>

<script>
import Vue from 'vue';
import Action from './Actions/Action'
import { mapState } from 'vuex';
import moment from 'moment'
import components from '../../common/tables/comps'

export default {
  components: {
  },
  data() {
    return {
      departmentSelected: {
      },
      loading: false,
      isOpenModalDelete: false,
      tableConfig: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: "table-bordered",
        tblStyle: "color: #666",
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          return [
            { title: "ID", field: "id", label: "ID", tdClass: "center", sortable: true, visible: false, },
            { title: "Name", field: "name", label: "Name", thClass: "left", tdClass: "left", sortable: true, visible: true },
            { title: "Users", field: "user_count", label: "Users", thClass: "left", tdClass: "left", sortable: true, visible: true },
            { title: "Installs", field: "install_count", label: "Installs", thClass: "left", tdClass: "left", sortable: true, visible: true },
            { title: 'Active', field: 'active', sortable: false, visible: true, tdClass: 'center', tdComp: "ActiveToggleTd", },
            { title: "", tdComp: Action, sortable: false, visible: true },
          ];
        })(),
        data: [],
        total: 0,
        // selection: [],  //if this is present the row selector shows up..
        summary: {},
        query: {},
        // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
        xprops: {
          eventbus: new Vue()
        }
      },
      submitDataOrg: {
        id: null,
        name: '',
        tags: [],
        scheduled_at: '',
        message: ''
      },
      submitData: {
        id: null,
        name: '',
        tags: [],
        send_at: 'now',
        scheduled_at: '',
        message: ''
      },
      isOpenAlertModal: false,
    };
  },

  created() {
    this.submitData = Vue.util.extend({}, this.submitDataOrg)

    this.tableConfig.xprops.eventbus.$on('toggle', this.toggleActive);

    this.tableConfig.xprops.eventbus.$on('onEdit', row => {
      this.$router.push({ name: 'business.departments.edit', params: { id: row.id } });
    })

    this.tableConfig.xprops.eventbus.$on('onDelete', row => {
      this.departmentSelected = row
      this.isOpenModalDelete = true
    })

    const offset = (this.page - 1 >= 0 ? this.page - 1 : 0) * this.perPage;
    this.tableConfig.query = {
      offset,
      limit: this.perPage,
    }
  },

  watch: {
    departments: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.tableConfig.data = newValue;
        }
      },
      deep: true,
    },
    total: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.tableConfig.total = newValue;
        }
      },
      deep: true,
    },
    'tableConfig.query': {
      handler() {
        this.loadDepartments();
      },
      deep: true
    }
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },

    agencyOwnerEmail () {
      return this.user.agency && this.user.agency.email
    },

    maxDepartments() {
      return this.$store.getters['auth/maxDepartments']
    },

    newDepartmentAvailable() {
      return this.departments.length < this.maxDepartments
    },

    ...mapState('department', {
      fetching: 'fetching',
      didFetch: 'didFetch',
      processing: 'processing',
      page: 'page',
      perPage: 'perPage',
      filter: 'filter',
      departments: 'data',
      total: 'total'
    })
  },
  mounted() {
    this.$store.dispatch('updateSubmenuOpened', true)
  },
  methods: {
    onRefresh() {
      this.loadDepartments()
    },

    closeModalAlert() {
      this.isOpenAlertModal = false
    },

    onNewDepartment() {
      if (!this.newDepartmentAvailable) {
        this.isOpenAlertModal = true
        return false;
      }
      this.$router.push({ name: 'business.departments.create' })
    },

    toggleActive(status) {
      this.$store.dispatch('department/toggleActive', status)
        .then(res => {
        })
        .catch(() => {
        })
    },

    loadDepartments() {
      const { query } = this.tableConfig;
      const page = query.offset ? Math.floor(query.offset / query.limit) + 1 : 1;
      const param = {
        page,
        per_page: query.limit,
        order: query.order,
        sort: query.sort
      }

      this.$store.dispatch('department/fetch', param)
    },

    closeModalDelete() {
      this.isOpenModalDelete = false
    },

    onDeleteDepartment() {

      this.$store
        .dispatch('department/delete', this.departmentSelected.id)
        .then(() => {
          this.isOpenModalDelete = false;
          this.loadDepartments();
        })
        .catch((err) => {
        })
    },
  },

};
</script>

<style lang="scss" scoped>

</style>
